<template>
  <div
    class="storyblok-form-newsletter"
  >
    <div class="storyblok-form-newsletter__overlay">
      <div ref="wrapperRef" class="storyblok-form-newsletter__overlay-container" data-lenis-prevent>
        <div class="storyblok-form-newsletter__overlay-container-inner">
          <div class="block-modalbox-overlay__container-inner">
            <button
              class="storyblok-form-newsletter__overlay-close"
              type="button"
              @click="modalStore.close()"
            >
              <IonIcon
                class="block-modalbox-overlay__close-icon"
                icon-name="close"
              />
            </button>
            <div class="storyblok-form-newsletter__content">
              <div
                v-if="!sentSuccessfully"
                class="storyblok-form-newsletter"
              >
                <div class="block-form-contact__form">
                  <AtomHeading
                    :text="title"
                    class="block-form-contact__form-title"
                    html-tag="h3"
                    font-size="h3"
                  />

                  <AtomRichtext :html="text" class="block-form-contact__form-text" />
                  <BlockFormController
                    :fieldsets="fields"
                    :button-text="btnText"
                    @on-submit="onSubmit"
                  />
                </div>
              </div>

              <transition name="fade-in">
                <div
                  v-if="statusMessage"
                  class="storyblok-form-newsletter__message"
                >
                  <AtomRichtext :html="`<p>${statusMessage}</p>`" />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    btnText: {
        type: String,
        default: '',
    },

    fields: {
        type: Array,
        default: () => [],
    },

    successMessage: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },
});

const { locale: currentLocale } = useI18n();
const language = currentLocale.value;

const wrapperRef = ref(null);
const modalStore = useNewsletterModalStore();

onClickOutside(wrapperRef, () => {
    modalStore.close();
});

const statusMessage = ref(null);
const sentSuccessfully = ref(false);
const onSubmit = async (data) => {
    try {
        await useFetch('/api/mailchimp_signup', {
            method: 'POST',
            body: { ...data, language },
        });

        statusMessage.value = props.successMessage;
        sentSuccessfully.value = true;

        return data;
    } catch (e) {
        statusMessage.value = props.errorMessage;
        useSentryError(e);
    } finally {
        window.setTimeout(() => {
            statusMessage.value = null;
            modalStore.close();
        }, 5000);
    }

    return false;
};
</script>

<style lang="scss" scoped>
.storyblok-form-newsletter {
    overscroll-behavior: contain;

}

.storyblok-form-newsletter__overlay {
    @include z-index('confirmationModal');

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    background: hsla(0,0%,100%,.3);

    @include mobile {
        align-items: flex-start;
    }
}

.storyblok-form-newsletter__overlay-container {
    position: relative;
    overflow: scroll;
    max-width: 960px;
    height: auto;
    max-height: 100%;

    @include remove-scrollbars;

    @include mobile {
        height: 100%;
        flex: 1;
        border-radius: 0;
    }
}

.storyblok-form-newsletter__message {
    margin-top: 40px;
}

.storyblok-form-newsletter__overlay-container-inner {
    position: relative;
    padding: 90px;
    margin:  var(--grid-gutter)  var(--page-padding);
    margin-top: var(--grid-gutter);
    margin-bottom: var(--grid-gutter);
    background: var(--c-white);
    box-shadow: 0 0 28px 0 rgba(49, 49, 49, 0.36);

    @include mobile {
        padding: 20px;
        margin-bottom: 0;
        box-shadow: none;
    }
}
.storyblok-form-newsletter__content {
    @include grid-columns(2,16);
    @include fluid('margin-bottom', 30px, 50px);

}

.storyblok-form-newsletter__overlay-close {
    position: absolute;
    top: 90px;
    right: 90px;
    cursor: pointer;
    @include mobile{
        top: 20px;
        right: 20px;
    }
}
.block-form-contact__form-title,
.block-form-contact__form-text {
    padding-bottom: 30px;
    @include mobile {
        padding-bottom: 20px;
    }
}

</style>
