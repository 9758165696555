<template>
  <div class="block-service-menu">
    <div
      v-if="serviceNavigationPhone.length > 0"
      class="block-service-menu__item"
    >
      <a
        :href="`tel:${serviceNavigationPhone}`"
        class="block-service-menu__item-link"
      >
        <IonIcon
          icon-name="contact-phone"
          class="block-service-menu__item-icon"
        />
        <span>{{ serviceNavigationPhone }}</span>
      </a>
    </div>
    <div v-if="showNewsletter" class="block-service-menu__item">
      <a
        class="block-service-menu__item-link"
        @click="toggleOpen"
        @keypress="toggleOpen"
      >
        <IonIcon
          icon-name="news"
          class="block-service-menu__item-icon"
        />
        <span class="c-uiicpn-service-menu__item-label">{{ getStoryblokTranslation('general', 'newsletter') }}</span>
      </a>
      <ClientOnly>
        <Teleport to="body">
          <StoryblokFormNewsletter
            v-if="openState"
            v-scroll-lock="openState"
            v-bind="formData"
            class="block-service-menu__newsletter"
            @blur="isMobile ? keepFocus($event) : null"
          />
        </Teleport>
      </ClientOnly>
    </div>
    <div
      v-if="serviceNavigationMail.length > 0"
      class="block-service-menu__item"
    >
      <UtilRouteLink
        v-if="data?.contact_link?.id || data?.contact_link?.url"
        class="block-service-menu__item-link"
        :link="data.contact_link"
      >
        <IonIcon
          icon-name="contact"
          class="block-service-menu__item-icon"
        />
        <span class="c-uiicpn-service-menu__item-label">{{ getStoryblokTranslation('general', 'contact') }}</span>
      </UtilRouteLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    formData: {
        type: Object,
        default: () => {},
    },
});

const { isMobile } = storeToRefs(useClientDeviceStore());
const showNewsletter = computed(() => props.data.show_service_navigation_newsletter);
const serviceNavigationMail = computed(() => props.data.service_navigation_mail);
const serviceNavigationPhone = computed(() => props.data.service_navigation_phone);

const keepFocus = (e) => {
    e.target?.focus();
};

const modalStore = useNewsletterModalStore();
const openState = computed(() => modalStore.getOpenState);
const toggleOpen = () => {
    modalStore.toggle();
};

const colorTopLevel = inject('colorSchema');
const color4 = computed(() => `var(--c-${colorTopLevel.value}-4)`);

// Scroll lock with lenis
const { lenis } = useLenis();

watch(openState, (newValue) => {
    if (newValue) {
        lenis?.value?.stop();
        document.body.classList.add('no-scroll');
    } else {
        lenis?.value?.start();
        document.body.classList.remove('no-scroll');
    }
});
</script>

<style lang="scss" scoped>

.block-service-menu {
    @include z-index('serviceNavigation');
    position: fixed;
    top: 535px;
    right: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    gap: 1px;

    @include tablet-portrait {
        position: fixed;
        top: auto;
        right: 0px;
        bottom: -69px;

        display: flex;
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;
        transform: rotate(90deg);
    }

    @include mobile {
        bottom: -75px;
        padding-left: 0px;
    }
}

.block-service-menu__item {
    position: relative;
    overflow: hidden;
}

.block-service-menu__item-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    margin: 23px;

    background-color: var(--c-white);
    text-align: center;

    @include tablet-portrait {
        margin: 12px;
        font-size: 15px;
        transform: rotate(-90deg);
    }
}

.block-service-menu__item-link {
    display: flex;
    align-items: center;
    padding-right: 20px;
    background-color:  var(--c-white);
    color: v-bind(color4);
    cursor: pointer;
    font-family: var(--f-family--thin);
    transform: translateX(100%) translateX(-75px);
    transition: transform 0.3s ease-out;

    span {
        word-break: normal;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        transform: translateX(0px);
    }

    @include tablet-portrait {
        transform: none;
    }
}
.c-uiicpn-service-menu__item-label {
    font-size: var(--f-size--a)
}
.block-service-menu__newsletter {
    height: calc(100% + 1px);
    overflow-y: 'auto';
}

</style>
